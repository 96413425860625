#home {
  position: relative;
  background: url("../../assets/header-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: 100vh;
  

  // disable the default padding styles
  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
 .head-text{
  color: white;
  padding-top: 150px;
  padding-bottom: 25px;
  text-shadow: 0px 0px 20px rgba(255, 255, 255, .5);

  @media screen and (max-width: 820px) {
    padding-top: 100px;
  }
 }
 .head-text span {
  color: var(--purple-color);
}
@media screen and (max-width: 820px) {
  padding: 0rem 0rem 5rem;
}
}

.app__header {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  


  padding: 4rem 2rem 2rem;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 820px) {
    padding: 0rem 0rem 0rem;
  }

  @media screen and (max-width: 450px) {
    padding: 0rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;

  margin: 0 2rem;
  
  .p-header-text{
    font-size: 1.3rem;
    line-height: 30px;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  color: rgba(255, 255, 255, 0.5);
  

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    color: rgba(255, 255, 255,1);
    background-color:rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.9);

  }

  .tag-cmp {
    flex-direction: column;
    // background-color: rgba(53, 24, 90, 1);
    p {
      width: 100%;
      text-transform: capitalize;
    }
  }

  span {
    font-size: 2.5rem;

    @media screen and (min-width: 2000px) {
      font-size: 5rem;
    }
  }

  @media screen and (max-width: 1200px) {
    justify-content: flex-start;
    align-items: center;
  }
}

.app__header-circles {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  height: 50%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background-color:rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.5);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 2.5rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;

    @media screen and (max-width: 900px) {
      width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  display: none;
}

  @media screen and (min-width: 2000px) {
    // div:nth-child(2) {
    //   width: 400px;
    //   height: 400px;
    // }

    // div:nth-child(3) {
    //   width: 170px;
    //   height: 170px;
    // }

    // div:nth-child(1) {
    //   width: 200px;
    //   height: 200px;
    // }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}
.app__header-circles-left {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: 50%;
  margin-left: 1rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    background-color:rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7);

    img {
      width: 60%;
      height: 60%;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 100px;
    height: 100px;
  }

  div:nth-child(2) {
    margin: 2.5rem;
    width: 150px;
    height: 150px;
  }

  div:nth-child(3) {
    width: 70px;
    height: 70px;

    @media screen and (max-width: 900px) {
      width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 600px) {
  display: none;
}

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 1rem;
    }
  }
}

.app__header-circles-center {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  margin: 0rem;

  div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color:rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7);

    img {
      width: 100%;
      height: 100%;
      border-radius: 500px;
    }
  }

  // circles with different height & width

  div:nth-child(1) {
    width: 400px;
    height: 400px;

    @media screen and (max-width: 600px) {
      width: 200px;
      height: 200px;
    }
    @media screen and (max-width: 1400px) {
      width: 300px;
      height: 300px;
    }
  }

  

  @media screen and (max-width: 600px) {
    div{
      margin: 1rem;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;

    div {
      margin: 0rem;
    }
  }
}


.app__header-img {
  flex: 1;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  

  img {
    width: 60%;
    height: 100%;
    object-fit: contain;
    z-index: 1;
    margin-left: 15px;
    background-color:rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.7);
    padding: 30px;
    @media screen and (max-width: 820px) {
      width: 30%;
    }
  }

  .overlay_circle {
    position: absolute;
    left: -2%;
    right: 0;
    bottom: -12%;
    z-index: 0;
    width: 100%;
    height: 130%;
  }

  @media screen and (max-width: 1200px) {
    margin: 2rem 0;
  }

  
}

.arrow-button {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  z-index: 1;
  background: none;
  border: none;
}

.arrow-button:hover {
  cursor: pointer;
}

.arrow-icon {
  animation-name: arrowBounce;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 10px;
}

@keyframes arrowBounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
