@import "../../../css-config/mixins.scss";

.hero-banner {
    padding: 40px 0;
    position: relative;
    padding-top: 200px;
    @include md {
        height: 100vh;
        padding-top:0px;
    }
    .content {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column-reverse;
        max-width: calc(100% - 20px);
        margin: 0 auto;
        position: relative;
        @include md {
            flex-direction: row;
            max-width: 1200px;
        }
        
        .banner-img {
            position: relative;
            z-index: 9;
            width: 200px;
            margin-bottom: 20px;
            margin-top: 20px;
            @include md {
                width: 335px;
                margin: auto;
                margin-top: 700px;
                margin-bottom: 0;
            }
            @include xxl {
                width: 600px;
                margin-right: 0;
                margin-top: 0;
            }
        }
        .text-content {
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-shadow: 0px 0px 20px rgba(255, 255, 255, .5);
            padding: 10px;
            @include md {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
            }
            @include xxl {
                left: 0;
                width: auto;
            }
            h1 {
                font-size: 40px;
                font-weight: 700;
                line-height: 1.2;
                color: white;
                margin-bottom: 20px;
                @include sm {
                    font-size: 80px;
                }
                @include md {
                    font-size: 100px;
                    line-height: 1.2;
                }
            }
            p {
                max-width: 300px;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 20px;
                @include md {
                    max-width: 500px;
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 40px;
                }
            }
            .ctas {
                display: flex;
                justify-content: center;
                gap: 20px;
                .banner-cta {
                    text-transform: uppercase;
                    font-size: 13px;
                    font-weight: 500;
                    width: fit-content;
                    border: 2px solid white;
                    padding: 10px 20px;
                    transition: all ease 0.3s;
                    cursor: pointer;
                    &.v2 {
                        background-color: white;
                        color: black;
                    }
                    &:hover {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}
